<template>
<b-container fluid>
  <bo-page-title />

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col lg="8">
          <h5 class="card-title">{{ pageTitle }} </h5>
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(mt_target)="data">
          {{ formatRupiah(data.value) }}
        </template>
        <template #cell(mt_achieve)="data">
          <span class="text-danger">{{ formatRupiah(data.value) }} ({{ round((data.value/data.item.mt_target)*100, 2) }}%) </span>
        </template>
        <template #cell(expense)="data">
          <span>{{ formatRupiah(data.value) }}</span>
        </template>
        <template #cell(margin)="data">
          <span>{{ formatRupiah(data.item.mt_achieve - data.item.expense) }}</span>
        </template>
        <template
          #cell(action)="data"
        >
          <router-link
            v-if="moduleRole('edit')"

            v-b-tooltip.hover
            title="Edit"
            class="icon_action"
            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
          >
            <i class="ti-marker-alt"></i>
          </router-link>

          <span v-if="!(moduleRole('delete') || moduleRole('changestatus') || moduleRole('edit'))">-</span>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="data.total > 10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage">Show </label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage"> items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
  </b-card>

  <template v-else>
    <Form :row.sync="row" :Config="Config" :mrValidation="mrValidation"/>
  </template>
  <div class="media-overlay" :class="showImage && detailImg ? 'show' : ''" @click.stop="showImage = false">
    <div class="media-content" @click.stop>
      <div class="media-dialog parent-wrapper">
        <b-img :src="uploader(detailImg)" />
        <button class="close-button"><i class="fas fa-times"></i></button>
      </div>
    </div>
  </div>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import Gen from '@/libs/Gen'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      maskCurrency:{
        decimal: ',', thousands: '.', 
        prefix: '', suffix: '', 
        precision:0, masked: false
      },
      idKey:'mt_id',
      mrValidation: {},
      showImage: false,
      detailImg: '',
      typeFilter: [
        { label: 'All Type', value: '' },
        { label: 'Yearly', value: 'Y' },
        { label: 'Monthly', value: 'M' },
      ],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'mt_year', label: 'Year', 
        },
        {
          key: 'mt_target', label: 'Target', 
        },
        {
          key: 'mt_achieve', label: 'Achievement', 
        },
        {
          key: 'expense', label: 'Expense', 
        },
        {
          key: 'margin', label: 'Margin', 
        },
        {
          key: 'action', 
        },
      ],
      mrMenuRel:[],
      monthList: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
    }
  },
  mounted(){
    this.apiGet({ callback: function(res, modulePage, router){
      if(res.data.status == 404){
        router.push({name: modulePage})
      }
    }})
    this.filter.type = this.$route.query.type || ''
  },
  methods: {
    round(num, decimalPlaces = 0) {
      var p = Math.pow(10, decimalPlaces)
      var n = (num * p) * (1 + Number.EPSILON)
      return Math.round(n) / p
    },
    setLightBox(src){
      this.detailImg = src
      this.showImage = true
    },
    submitTarget(ref, refs){
      const total = this.row.month.reduce((a, b) => {
        return a + b.mt_target
        }, 0)

      if(total > this.row.mt_target){
        return this.$swal({
          title: 'Monthly total exceeds target!',
          text: 'please check the monthly target and make sure the total does not exceed the target.',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

      refs[ref].validate().then(success => {
        if(!success) return

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: this.row.mt_id ? 'update' : 'add-target', ...this.row} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$router.push({name: this.$route.name})
              })
          }
        }).catch(err => {
          this.$swal({
            title: err.response?.data?.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        })
      })
    }
  },
  watch:{
    $route(){
      this.apiGet({callback: function(res, modulePage, router){
        if(res.data.status == 404){
          router.push({name: modulePage})
        }
      }})
    },
    'filter'(){
      if('type' in this.filter == false){
        this.$set(this.filter, 'type', '')
      }
    }
  }
}
</script>